import React from 'react';
import Board from '../Board';
import MoveTable from '../MoveTable';
import NavBar from '../NavBar';
import TagsDropdown from '../TagsDropdown';

import styled from 'styled-components';

import { DataContext } from '../DataProvider';
import { BLACK } from 'chess.js';

function Body() {
  const {
    tags,
    setTags,
    toggle,
    setToggle,
    notes,
    setNotes,
    isMistake,
    setIsMistake,
    isPlanningMode,
    setIsPlanningMode,
    playerSolvedExercise,
    playerFailedExercise,
    numCorrectExercisePlan,
    exercisePlans,
  } = React.useContext(DataContext);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Logic for what happens on submit
  // };

  return (
    <Wrapper>
      <NavBar />
      <Content>
        <BoardWrapper>
          <Board />
        </BoardWrapper>
        <MoveTableWrapper>
          <MoveTable />
        </MoveTableWrapper>
        <FormWrapper>
          <TagsDiv>
            <TagsDropdown />
          </TagsDiv>

          <Input
            type='text'
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            style={{ color: 'black' }}
          />
          {playerSolvedExercise && (
            <SolvedIndicatorsContainer>
              <SolvedIndicator>✓</SolvedIndicator>
              {Array.from({ length: numCorrectExercisePlan }).map(
                (_, index) => (
                  <SolvedIndicator
                    key={index}
                    style={{ width: '15px', height: '15px', marginLeft: '5px' }}
                  />
                )
              )}
              {numCorrectExercisePlan === exercisePlans.length && (
                <SolvedIndicator>✓</SolvedIndicator>
              )}
            </SolvedIndicatorsContainer>
          )}
          {playerFailedExercise && <FailedIndicator>✕</FailedIndicator>}
          <div>
            <ToggleButton
              onClick={() => setToggle('training')}
              style={{
                backgroundColor:
                  toggle === 'training' ? 'lightgreen' : 'lightgrey',
                color: 'black',
              }}
            >
              Training
            </ToggleButton>
            <ToggleButton
              onClick={() => setToggle('recording')}
              style={{
                backgroundColor:
                  toggle === 'recording' ? 'lightgreen' : 'lightgrey',
                color: 'black',
              }}
            >
              Recording
            </ToggleButton>
          </div>
          <TextAreaDiv>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              style={{ width: '100%', height: '150px' }}
            />
          </TextAreaDiv>
          <div>
            <CheckboxLabel>
              <Checkbox
                type='checkbox'
                checked={isMistake}
                onChange={(e) => setIsMistake(e.target.checked)}
              />
              Mistake
            </CheckboxLabel>
          </div>
          <div>
            <CheckboxLabel>
              <Checkbox
                type='checkbox'
                checked={isPlanningMode}
                onChange={(e) => setIsPlanningMode(e.target.checked)}
              />
              Planning Mode
            </CheckboxLabel>
          </div>
          {/* <SubmitButton onClick={handleSubmit}>Submit</SubmitButton> */}
        </FormWrapper>
      </Content>
    </Wrapper>
  );
}

export default Body;

const TextAreaDiv = styled.div`
  margin-top: 20px;
`;

const TagsDiv = styled.div`
  margin-bottom: 20px;
`;

const ToggleButton = styled.button`
  margin-top: 20px;
  padding: 5px;
`;

const Wrapper = styled.div`
  background-color: #161512;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  height: 100%;
`;

const BoardWrapper = styled.div`
  flex: 65;
  background-color: #161512;
  padding-top: 60px;
`;

const MoveTableWrapper = styled.div`
  flex: 15;
  background-color: #161512;
`;

const FormWrapper = styled.div`
  padding-top: 60px;
  padding-left: 15px;
  background-color: #161512;
  color: white;
  flex: 20;
`;

const Input = styled.input`
  padding: 5px;
  background-color: white;
`;

const CheckboxLabel = styled.label`
  display: block;
  margin: 10px 0;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
  -webkit-appearance: checkbox; /* For WebKit browsers */
  -moz-appearance: checkbox; /* For Mozilla browsers */
  appearance: checkbox; /* Standard syntax */
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: lightblue;
  border: none;
  cursor: pointer;
`;

const SolvedIndicatorsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SolvedIndicator = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
`;

const FailedIndicator = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
`;
