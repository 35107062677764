import Body from '../Body';
import React from 'react';

import DataProvider from '../DataProvider';
import UserProvider from '../UserProvider';

function App() {
  return (
    <UserProvider>
      <DataProvider>
        {/* <div className='wrapper'>
          <Header />
        </div> */}
        <Body />
      </DataProvider>
    </UserProvider>
  );
}

export default App;
