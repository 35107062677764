import React from 'react';
import * as Select from '@radix-ui/react-select';
// import classnames from 'classnames';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@radix-ui/react-icons';
import './styles.css';

const TagsDropdown = () => (
  <Select.Root>
    <Select.Trigger
      className='TagsDropdownSelectTrigger'
      aria-label='Variation'
    >
      <Select.Value placeholder='Select a variation' />
      <Select.Icon className='TagsDropdownSelectIcon'>
        <ChevronDownIcon />
      </Select.Icon>
    </Select.Trigger>
    <Select.Portal>
      <Select.Content className='TagsDropdownSelectContent'>
        <Select.ScrollUpButton className='TagsDropdownSelectScrollButton'>
          <ChevronUpIcon />
        </Select.ScrollUpButton>
        <Select.Viewport className='TagsDropdownSelectViewport'>
          <Select.Group>
            <Select.Label className='TagsDropdownSelectLabel'>
              Variations
            </Select.Label>
            <SelectItem value='apple'>Apple</SelectItem>
            <SelectItem value='banana'>Banana</SelectItem>
            <SelectItem value='blueberry'>Blueberry</SelectItem>
            <SelectItem value='grapes'>Grapes</SelectItem>
            <SelectItem value='pineapple'>Pineapple</SelectItem>
          </Select.Group>

          <Select.Separator className='TagsDropdownSelectSeparator' />

          <Select.Group>
            <Select.Label className='TagsDropdownSelectLabel'>
              Vegetables
            </Select.Label>
            <SelectItem value='aubergine'>Aubergine</SelectItem>
            <SelectItem value='broccoli'>Broccoli</SelectItem>
            <SelectItem
              className='TagsDropdownSelectItem'
              value='carrot'
              disabled
            >
              Carrot
            </SelectItem>
            <SelectItem value='courgette'>Courgette</SelectItem>
            <SelectItem value='leek'>Leek</SelectItem>
          </Select.Group>

          <Select.Separator className='TagsDropdownSelectSeparator' />

          <Select.Group>
            <Select.Label className='TagsDropdownSelectLabel'>
              Meat
            </Select.Label>
            <SelectItem value='beef'>Beef</SelectItem>
            <SelectItem value='chicken'>Chicken</SelectItem>
            <SelectItem value='lamb'>Lamb</SelectItem>
            <SelectItem value='pork'>Pork</SelectItem>
          </Select.Group>
        </Select.Viewport>
        <Select.ScrollDownButton className='TagsDropdownSelectScrollButton'>
          <ChevronDownIcon />
        </Select.ScrollDownButton>
      </Select.Content>
    </Select.Portal>
  </Select.Root>
);

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={'TagsDropdownSelectItem'}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className='TagsDropdownSelectItemIndicator'>
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default TagsDropdown;
