import React from 'react';
import styled from 'styled-components';

import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';

function MoveTable() {
  const { moveHistory } = React.useContext(DataContext);

  // Process moves into pairs
  const movePairs = [];
  for (let i = 0; i < moveHistory.length; i += 2) {
    movePairs.push({
      white: moveHistory[i],
      black: moveHistory[i + 1] ? moveHistory[i + 1] : '',
    });
  }

  return (
    <StyledTable>
      <tbody>
        {movePairs.map((pair, index) => (
          <StyledRow key={index}>
            <StyledMoveIndexCell>{index + 1}</StyledMoveIndexCell>
            <StyledCell>{pair.white}</StyledCell>
            <StyledCell>{pair.black}</StyledCell>
          </StyledRow>
        ))}
      </tbody>
    </StyledTable>
  );
}

export default MoveTable;

const StyledTable = styled.table`
  width: 100%;
  padding-top: 60px;
`;

const StyledRow = styled.tr`
  display: flex;
`;

const StyledMoveIndexCell = styled.td`
  flex: 1;
  background-color: #302e2c;
  color: #6b6b6b;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledCell = styled.td`
  flex: 10;
  padding-top: 5px;
  padding-bottom: 5px;
  &:nth-child(2),
  &:nth-child(3) {
    padding-left: 20px;
  }
  background-color: #262421;
  color: #bababa;
  cursor: pointer;
  &:hover {
    background-color: #3692e7;
    color: #ffffff;
  }
  &:last-child:empty {
    cursor: default;
    background-color: #262421;
  }
`;
